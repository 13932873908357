/* eslint-disable react/prop-types */
import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '@/components/Seo'
import { cleanProps } from '@/utils'
import Blocks from '@/utils/Blocks'
import useConstant from '@/hooks/useConstant'
/* eslint-disable no-unused-vars */
import { pageQuery, postThumbQuery } from '@/queries'
/* eslint-enable no-unused-vars */
import * as T from '@/types'

function IndexPage({
	pageContext: { media, wordpress_id },
	data: {
		posts: { nodes: posts },
		page: { blocks, yoast, featured_media }
	}
}) {
	const data = useConstant(() => cleanProps(blocks, media))
	const images = useConstant(() => JSON.parse(media))
	const news = useConstant(() =>
		posts.map((post, index) => {
			const category =
				post.categories && post.categories.length
					? post.categories[0].name
					: 'News'

			return {
				id: index,
				copy: '',
				ctalink: post.path,
				ctatext: 'Read more',
				heading: post.title,
				title: category,
				image:
					images[
						post.featured_image_url && post.featured_image_url.wordpress_id
					],
				landscape_image: 
					images[
						post.acf.slider_image && post.acf.slider_image.wordpress_id
					]
			}
		})
	)
	
	// Add in news 2 items before the end
	data.splice( data.length - 2, 0, {
		type: 'acf/use-case-card-slider',
		id: 2389124,
		slides: news
	});

	return (
		<>
			<SEO {...yoast} image={featured_media} />
			<Blocks data={data} media={media} currentPage={wordpress_id} />
		</>
	)
}

IndexPage.propTypes = {
	pageContext: T.pageContext.isRequired,
	data: shape({
		page: T.page.isRequired
	})
}

export const query = graphql`
	query IndexQuery($path: String!) {
		page: wordpressPage(path: { eq: $path }) {
			...pageQuery
		}
		posts: allWordpressPost(limit: 6, sort: { fields: date, order: DESC }) {
			nodes {
				...postThumbQuery
			}
		}
	}
`

export default IndexPage
